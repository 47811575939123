// Dependencies
import React from 'react'
import { motion } from 'framer-motion'

class RoomSlots extends React.Component {

	
	constructor(props) {
		super(props)
		if(!props.roomRange) throw new Error('roomRange is not defined')
	}


	render() {
		const { roomRange, roomWatching } = this.props

		return <motion.div className='roomRange'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			{
				Object.keys(roomRange).map((key, index) => {
					// console.log(roomWatching, key, roomRange[index], roomWatching == roomRange[index].id)
					return <motion.div
						key={'parent-room-card-' + key}
					>
						<h2>Remainder {roomRange[index].remainder}</h2>
						<motion.div className='item' key={'room-range-item-'+key}
							initial={{
								opacity: 0,
								scale: 0.5,
							}}
							animate={{
								opacity: 1,
								scale: 1,
								borderColor: roomWatching == roomRange[index].id ? 'blue' : '#e1e1e1',
							}}
							transition={{ 
								duration: 0.5,
								delay: index * 0.1,
							}}
						>
							{
								roomRange[key].conferences.map((conf, index2) => {
									return <motion.div className='itemConf' key={'conf-id-item-' + conf.remainder + '-' + conf.id}
											layoutId={'meeting-item-' + conf.remainder + '-' + conf.id}
											style={{
												width: `calc(${(conf.remainder / 7) * 100}% - 10px)`,
												backgroundColor: `hsl(${(conf.id / 7) * 360}, 100%, 50%)`,
											}}
											animate={{
												opacity: 1,
												scale: 1,
											}}
											transition={{ 
												duration: 0.3,
											}}
										>
											<span>{conf.remainder}</span>
										</motion.div>
								})
							}
						</motion.div>
					</motion.div>
				})
			}
        </motion.div>
	}
}

export default RoomSlots;