/* Dependencies */

/* Function */
export async function solver(fileContent, callback) {
    if(!callback) throw new Error('Callback is not defined');
    // Timer.
    const startTime = Date.now();

    // Create Datastore.
    let roomCount = 0;
    let conferenceCount = 0;
    let times = [];

    // Read input file line-by-line
    for (const line of fileContent.split(/[\r\n]+/)) {
        const data = line.split(":");
        if (data[0] === "i") {
            roomCount = parseInt(data[1]);
            continue;
        }

        if (data[0] === "j") {
            conferenceCount = parseInt(data[1]);
            continue;
        }

        if (data[0] === "t") {
            times = data[1].split(",").map(x => parseInt(x));
            continue;
        }

        console.log(`[WARN] input data contains malformed input type: ${data[0]}, Expected: i, j, or t`);
    }

    // Sanity Checks
    if (roomCount <= 0) {
        console.log("[ERR] Check Failed. Room Count (i) should be more than 0");
        callback({ state: 'exit', data: "Check Failed. Room Count (i) should be more than 0" });
    }

    if (conferenceCount <= 0) {
        console.log("[ERR] Check Failed. Conference Count (j) should be more than 0");
        callback({ state: 'exit', data: "Check Failed. Conference Count (j) should be more than 0" });
    }

    if (times.length !== conferenceCount) {
        console.log("[ERR] Check Failed. Conference Count (j) should be equal to the number of times provided");
        callback({ state: 'exit', data: "Check Failed. Conference Count (j) should be equal to the number of times provided" });
    }

    callback({ state: 'roomCount', data: roomCount });
    callback({ state: 'times', data: times });
    await sleep(2000);
    
    // Build conference data.
    const conferences = {};
    for (let i = 1; i <= conferenceCount; i++) {
        const t = times[i - 1];
        if (!conferences[t]) {
            conferences[t] = [];
        }

        conferences[t].push(i);
        callback({ state: 'conferences', data: conferences });
        callback({ state: 'timesPush', data: i - 1 });
        await sleep(200);
    }

    callback({ state: 'conferences', data: conferences });

    // Build room range for chunk.
    const roomRange = [];
    for (let i = 1; i <= roomCount; i++) {
        roomRange.push({ id: i, remainder: 7, conferences: [] });
    }

    callback({ state: 'roomRange', data: roomRange });
    await sleep(2000);

    console.log('[INFO] Data Sanity Check Passed');
    console.log('[INFO] Room Count (i):', roomCount);
    console.log('[INFO] Conference Count (j):', conferenceCount);
    
    /* ------------------ Solver ------------------ */
    // Prepare the results.
    // const writer = createWriteStream('./output.txt', { flags: 'w' });

    // For loop each room that containing a remaining hours
    let output = "";
    for (const room of roomRange) {
        //console.log(room.id)

        if (room.id > 1) {
            output += `\r\n`;
        }

        output += `${room.id}:`;
        callback({ state: 'roomWatching', data: room.id });

        // Hours limit that each room can handle
        let remaining = room.remainder || 7;

        // Then on each room, Lookup for the conference that have the longest hours (but still capable)
        while (remaining > 0) {
            // Find the conference that have the longest hours (but still capable)
            let remainder = remaining;
            callback({ state: 'conferencesWatching', data: remainder });
            await sleep(250);
            let id = (conferences[remainder] && conferences[remainder].length > 0) ? conferences[remainder].pop() : null;
            
            while (!id) {
                remainder -= 1;
                callback({ state: 'conferencesWatching', data: remainder });
                await sleep(250);
                if (remainder <= 0) {
                    break;
                }

                id = (conferences[remainder] && conferences[remainder].length > 0) ? conferences[remainder].pop() : null;
            }

            // If no conference is found, break the loop.
            if (!id) {
                break;
            }

            // Deduct remaining hours.
            remaining -= remainder;

            // We found the best match, Assign the conference to the room.
            output += `${id}(${remainder}),`;
            room.conferences.push({ id, remainder });
            room.remainder = remaining;
            callback({ state: 'conferences', data: conferences });
            callback({ state: 'roomRange', data: roomRange });
            await sleep(500);
        }
        callback({ state: 'conferencesWatching', data: null });
        callback({ state: 'roomWatching', data: null });

        // writer.write(output.slice(0, -1));
    }

    // Finished Timer.
    console.log('[INFO] Solved');
    const endTime = Date.now();
    console.log('[INFO] Execution Time:', (endTime - startTime) / 1000, 'seconds');

    // Non-Assigned conferences.
    
    for (const hours in conferences) {
        if (hours === '1') {
            output += `\r\n0:`;
        }

        for (const id of conferences[hours]) {
            output += `${id}(${hours}),`;
        }
    }

    callback({
        state: 'success',
        data: output
    });

    // Write result into output
    console.log('[INFO] Output saved to: output.txt');
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}