// Dependencies
import React from 'react'
import { motion } from 'framer-motion'

class TimeSlots extends React.Component {

	
	constructor(props) {
		super(props)
		if(!props.times) throw new Error('roomRange is not defined')
	}


	render() {
		const { times, timeAdded } = this.props

		return <motion.div className='times'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			{
				times.map((t, index) => {
					if(timeAdded.indexOf(index) !== -1) {
						console.log('meeting-item-' + t + '-' + index)
						return <></>
					}
					return <motion.div className='item' key={'time-id-item-' + t + '-' + index}
							layoutId={'meeting-item-' + t + '-' + (index + 1)}
							style={{
								width: `calc(${(t / 7) * 100}% - 10px)`,
								backgroundColor: `hsl(${((index + 1) / 7) * 360}, 100%, 50%)`,
							}}
							initial={{
								opacity: 0,
							}}
							animate={{
								opacity: 1,
							}}
							transition={{ 
								duration: 0.5,
							}}
						>
							<span>{t}</span>
						</motion.div>
				})
			}
        </motion.div>
	}
}

export default TimeSlots;