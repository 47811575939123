// Dependencies
import React from 'react'
import { motion } from 'framer-motion'

class MeetingCards extends React.Component {

	
	constructor(props) {
		super(props)
		if(!props.conferences) throw new Error('Conferences is not defined')
	}


	render() {
		const { conferences, conferencesWatching } = this.props

		return <motion.div className='conferences'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			{
				Object.keys(conferences).map((key, index) => {
					const ids = conferences[key];
					return <motion.div>
						<h1 className='length' key={'set-key-' + key}>{key}</h1>
						<motion.div className='setItem' key={'set-' + key}
							layoutId={'set-' + key}
							animate={{
								borderColor: conferencesWatching == key ? 'red' : 'transparent',
							}}
							transition={{
								duration: 0.2
							}}
						>
							{
								ids.map((id, index2) => {
									return <motion.div className='item' key={'id-item-' + key + '-' + id}
										layoutId={'meeting-item-' + key + '-' + id}
										style={{
											width: `${(key / 7) * 100}%`,
											backgroundColor: `hsl(${(id / 7) * 360}, 100%, 50%)`,
										}}
										initial={{ 
											opacity: 0,
										}}
										animate={{
											opacity: 1,
										}}
										transition={{
											duration: 0.2,
										}}
									>
										<span>{key}</span>
									</motion.div>
								})
							}
						</motion.div>
					</motion.div>
				})
			}
        </motion.div>
	}
}

export default MeetingCards;