// Dependencies
import React from 'react'
import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion'

// Library
import { solver } from './solver'

// Components
import MeetingCards from './components/meeting-cards'
import RoomSlots from './components/room-slots'
import TimeSlots from './components/time-slots'

class App extends React.Component {

	constructor(props) {
		super(props)
		this.showFile = this.showFile.bind(this)
		this.onStateChange = this.onStateChange.bind(this)
	}

	state = {
		fileContent: null,
		times: [],
		timeAdded: [],
		roomWatching: 0,
		conferences: {},
		conferencesWatching: null,
		roomRange: [],
		results: {},
		success: ""
	}

	onStateChange = (obj) => {
		const { state, data } = obj
		// console.log(obj)
		if(state === "timesPush") {
			this.setState({
				timeAdded: [...this.state.timeAdded, data]
			})
			return
		}
		this.setState({
			[state]: data,
		})
		
	}

	render() {
		const { 
			times,
			timeAdded,
			fileContent,
			conferences,
			conferencesWatching,
			roomRange,
			roomWatching,
			success
		} = this.state

		return <div className="App">
			<AnimateSharedLayout mode="wait">
				<AnimatePresence>
					<motion.div className="success"
						style={{
							display: success !== "" ? "block" : "none"
						}}
					>
						<h1>Result</h1>
						<span>{success}</span>
					</motion.div>
					{ !fileContent &&
						<div className='chooseFile'>
							<input id="inputChooseFile" type="file" onChange={(e) => this.showFile(e)} />
							<button onClick={()=>{
								document.getElementById('inputChooseFile').click()
							}}>Choose file</button>
						</div>
					}
					{ fileContent &&
						<div className='col-2'>
							<div className='content-side'>
								<MeetingCards conferences={conferences} conferencesWatching={conferencesWatching}/>
							</div>
							<div className='content-side'>
								{roomRange.length > 0 &&
									<RoomSlots roomRange={roomRange} roomWatching={roomWatching}/>
								}
								{times.length > 0 && roomRange.length == 0 &&
									<TimeSlots times={times} timeAdded={timeAdded}/>
								}
							</div>
						</div>
					}
				</AnimatePresence>
			</AnimateSharedLayout>
		</div>
	}

	showFile = async (e) => {
		e.preventDefault()
		const reader = new FileReader()
		reader.onload = async (e) => { 
			const text = (e.target.result)
			this.setState({ fileContent: text })
			solver(text, this.onStateChange)
		};
		reader.readAsText(e.target.files[0])
	}
}

export default App;
